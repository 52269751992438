import axios from 'axios'

class StorageFacilityService {
  index () {
    return axios.get('/api/provider-account/storage-facilities')
  }

  get (id) {
    return axios.get('/api/provider-account/storage-facilities/' + id)
    // return axios({
    //   method: 'get',
    //   url: '/api/provider-account/warehouse',
    //   data: { id: id }
    // })
  }

  create (storageFacility) {
    const data = new FormData()
    data.append('name', storageFacility.name)
    data.append('address', storageFacility.address)
    data.append('address2', storageFacility.address2)
    data.append('city', storageFacility.city)
    data.append('state', storageFacility.state)
    data.append('zip', storageFacility.zip)
    // data.append('img_temp_file_id', brand.imgTempFileId)
    return axios.post('/api/provider-account/storage-facilities', data)
  }

  update (id, storageFacility) {
    const data = new FormData()
    data.append('_method', 'PUT')
    if (storageFacility.name) data.append('name', storageFacility.name)
    if (storageFacility.name) data.append('address', storageFacility.address)
    if (storageFacility.name) data.append('address2', storageFacility.address2)
    if (storageFacility.name) data.append('city', storageFacility.city)
    if (storageFacility.name) data.append('state', storageFacility.state)
    if (storageFacility.name) data.append('zip', storageFacility.zip)
    // data.append('imgFile', productTypeData.imgFile)
    // return Api.post('/api/provider-account/warehouses/' + id + '?_method=PUT', data)
    return axios({
      method: 'post',
      url: '/api/provider-account/storage-facilities/'+id,
      data: data
    })
  }

  destroy (id) {
    return axios({
      method: 'delete',
      url: '/api/provider-account/storage-facilities',
      data: { id: id }
    })
  }
}

export default new StorageFacilityService()
