import axios from 'axios'

class OrderService {
  index (page = 1, per_page = 10, query = '') {
    let param_str = '?page=' + page + '&per_page=' + per_page;
    if (query != '') param_str += '&query=' + query;
    return axios.get('/api/client-account/orders' + param_str)
  }
}

export default new OrderService()
