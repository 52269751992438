import axios from 'axios'
import moment from 'moment'

class ReceiveInboundShipmentService {
  create (inboundShipment) {
    const data = new FormData()
    data.append('inbound_shipment_id', inboundShipment.inbound_shipment_id)
    data.append('tracking_num', inboundShipment.tracking_num)
    data.append('carrier', inboundShipment.carrier)
    data.append('eta_start_time', moment(inboundShipment.eta_start_time).format())
    data.append('notes', inboundShipment.notes)
    data.append('received_products', JSON.stringify(inboundShipment.received_products))
    data.append('temp_file_ids', JSON.stringify(inboundShipment.temp_file_ids))
    data.append('close', inboundShipment.close)
    return axios.post('/api/provider-account/receive-inbound-shipments', data)
  }
}

export default new ReceiveInboundShipmentService()
