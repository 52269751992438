<template>
  <div>
    <div class="card" v-loading="isLoading">
      <div class="card-header">
        <h5 class="card-title">Products</h5>
        <p-button type="info" size="sm" @click.prevent="goAddProduct()">Add Product</p-button>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table :data="products" header-row-class-name="text-primary" ref="table" :row-key="getRowKey">
            <el-table-column prop="name"
                             label="Name">
            </el-table-column>
            <el-table-column prop="warehouse_part_num"
                             label="Part Num">
            </el-table-column>
            <el-table-column prop="client_account.business_name"
                             label="Client">
            </el-table-column>
            <el-table-column prop="qty_on_hand"
                             label="Qty On Hand">
            </el-table-column>
            <el-table-column prop="qty_allocated"
                             label="Qty Allocated">
            </el-table-column>
            <el-table-column label="Qty Available">
              <template slot-scope="props">
                {{ qtyAvailable(props.row) }}
              </template>
            </el-table-column>
            <el-table-column prop="qty_inbound"
                             label="Qty Inbound">
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label="Actions">
              <template slot-scope="props">
                <p-button type="warning" size="sm" icon @click="toogleExpand(props.row)">
                  <i class="fa fa-angles-down"></i>
                </p-button>
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
            <el-table-column type="expand" width="1">
             <template slot-scope="props">
              <el-table :data="props.row.stock_assignments" :border="true" :row-key="getRowKey">
                <el-table-column label="Qty" prop="qty">
                </el-table-column>
                <el-table-column label="Status">
                  <template slot-scope="props">
                    {{ displayStatus(props.row) }}
                  </template>
                </el-table-column>
                <el-table-column label="Condition">
                  <template slot-scope="props">
                    {{ displayCondition(props.row) }}
                  </template>
                </el-table-column>
                <el-table-column label="Received At">
                  <template slot-scope="props">
                    {{ displayReceived(props.row) }}
                  </template>
                </el-table-column>
                <el-table-column label="Load">
                  <template slot-scope="props">
                    {{ displayLoadNum(props.row) }}
                  </template>
                </el-table-column>
                <el-table-column label="Location">
                  <template slot-scope="props">
                    {{ displayLocation(props.row) }}
                  </template>
                </el-table-column>
                <el-table-column label="Transfer">
                  <template slot-scope="props">
                    <p-button v-if="batchReceived(props.row)" type="warning" size="sm" icon @click="requestTransfer(props.$index, props.row)">
                      <i class="fa fa-arrows-left-right"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
             </template>
           </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import Swal from 'sweetalert2';
  import {Table, TableColumn} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import ProductService from 'src/services/ProviderAccount/product.service'
  Vue.use(Table)
  Vue.use(TableColumn)
  export default{
    components: {
      PSwitch
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data () {
      return {
        isLoading: true,
        products: [],
        stockConditions: [],
        stockStatuses: []
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        this.isLoading = true
        Promise.all([this.loadProducts(), this.loadStockConditions(), this.loadStockStatuses()]).then((values) => {
          this.isLoading = false
        })
      },
      async loadProducts () {
        let response = await ProductService.index()
        this.products = response.data
      },
      async loadStockConditions () {
        let response = await ProductService.getStockConditions()
        this.stockConditions = response.data
      },
      async loadStockStatuses () {
        let response = await ProductService.getStockStatuses()
        this.stockStatuses = response.data
      },
      goAddProduct () {
        this.$router.replace({ name: 'Add Product' })
      },
      getRowKey (row) {
        return row.id
      },
      handleLike (index, row) {
        alert(`Your clicked on Like button`)
      },
      handleEdit (index, row) {
        this.$router.push({ name: 'Edit Product', params: { id: row.id } })
      },
      handleDelete (index, row) {
        const swalWithBootstrapButtons4 = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons4
          .fire({
            title: `Are you sure?`,
            text: `${row.name} will be permanently deleted!`,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              this.deleteProduct(row)
            }
          });
      },
      async deleteProduct (product) {
        try {
          this.isLoading = true
          await ProductService.destroy(product.id)
          this.showNotify('Product deleted', 'success', 'Success')
          this.isLoading = false
          this.init()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.isLoading = false
        }
      },
      qtyAvailable (product) {
        return product.qty_on_hand - product.qty_allocated
      },
      async toogleExpand(row) {
        let $table = this.$refs.table
        if (row.stock_assignments === undefined) {
          this.isLoading = true
          let response = await ProductService.getStock(row.id)
          row.stock_assignments = response.data.stock_assignments
          this.isLoading = false
        }
        $table.toggleRowExpansion(row)
      },
      displayLoadNum (stockAssignment) {
        if (stockAssignment.stock_load !== null) return stockAssignment.stock_load.barcode_id;
        else return 'none';
      },
      displayLocation (stockAssignment) {
        if (stockAssignment.stock_load !== null && stockAssignment.stock_load.storage_location !== null) return stockAssignment.stock_load.storage_location.barcode_id;
        else return 'none';
      },
      displayStatus (stockAssignment) {
        if (stockAssignment.stock_status !== null) return stockAssignment.stock_status.name;
        else return 'none';
      },
      displayCondition (stockAssignment) {
        if (stockAssignment.stock_condition !== null) return stockAssignment.stock_condition.name;
        else return 'none';
      },
      displayReceived (stockAssignment) {
        if (stockAssignment.received_at !== null) return stockAssignment.received_at;
        else return 'Not Received';
      },
      batchReceived (stockAssignment) {
        return stockAssignment.received_at !== null
      },
      transferStock (stockAssignment) {
        return ''
      },
      requestTransfer (index, stockAssignment) {
        const swalConfirm = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        swalConfirm
          .fire({
            title: `Transfer Inventory`,
            html: `<div id="xfer-inventory-content"></div>`,
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              let xfer_data = {
                id: stockAssignment.id,
                qty: document.getElementById("qty").value,
                stock_condition_id: document.getElementById("stock_condition_id").value,
                stock_status_id: document.getElementById("stock_status_id").value,
                stock_load_barcode_id: document.getElementById("stock_load_barcode_id").value,
              }
              this.submitTransfer(xfer_data)
            }
          });

        swalConfirm.showLoading()
        let xferHtmlElement = document.getElementById("xfer-inventory-content");
        this.updateXferHtml(xferHtmlElement, stockAssignment)
        swalConfirm.hideLoading()

      },
      async submitTransfer (stockAssignment) {
        try {
          this.isLoading = true
          let response = await ProductService.transferStock(stockAssignment);
          this.updateProductData(response.data)
          this.isLoading = false
        } catch (e) {
          this.isLoading = false
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.loading = false
        }
      },
      updateProductData(productDeep) {
        var p_index = -1
        for (var i = 0; i < this.products.length; i++) {
          if (this.products[i].id === productDeep.id) {
            p_index = i
            break
          }
        }
        this.$set(this.products, p_index, productDeep)
      },
      updateXferHtml (xferHtmlElement, stockAssignment) {
        xferHtmlElement.innerHTML = this.xferHtml(stockAssignment)
      },
      xferHtml (stockAssignment) {
        let html = `<table class="xfer-table">
          <tr>
            <td>
              <div class="form-group has-label">
                <label>Available Qty</label>
                <input id="current_qty" value="`+stockAssignment.qty+`" type="text" class="form-control" disabled />
              </div>
            </td>
            <td>
              <div class="form-group has-label">
                <label>Transfer Qty</label>
                <input id="qty" type="text" class="form-control" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-group has-label">
                <label>From Condition</label>
                <input id="current_stock_condition_id" value="`+stockAssignment.stock_condition.name+`" type="text" class="form-control" disabled />
              </div>
            </td>
            <td>
              <div class="form-group has-label">
                <label>To Condition</label><br>
                <select name="stock_condition_id" id="stock_condition_id" class="form-control">`
        for (var i = 0; i < this.stockConditions.length; i++) {
          html = html + '<option value="' + this.stockConditions[i].id + '"'
          if (this.stockConditions[i].id === stockAssignment.stock_condition.id) html = html + ' selected'
          html = html + '>' + this.stockConditions[i].name + '</option>'
        }
        html = html + `</select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-group has-label">
                <label>From Status</label>
                <input id="current_stock_status_id" value="`+stockAssignment.stock_status.name+`" type="text" class="form-control" disabled />
              </div>
            </td>
            <td>
              <div class="form-group has-label">
                <label>To Status</label><br>
                <select name="stock_status_id" id="stock_status_id" class="form-control">`
        for (var i = 0; i < this.stockStatuses.length; i++) {
          html = html + '<option value="' + this.stockStatuses[i].id + '"'
          if (this.stockStatuses[i].id === stockAssignment.stock_status.id) html = html + ' selected'
          html = html + '>' + this.stockStatuses[i].name + '</option>'
        }
        html = html + `</select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-group has-label">
                <label>From Load</label>
                <input id="current_stock_load_barcode_id" value="`+stockAssignment.stock_load.barcode_id+`" type="text" class="form-control" disabled />
              </div>
            </td>
            <td>
              <div class="form-group has-label">
                <label>To Load</label>
                <input id="stock_load_barcode_id" value="`+stockAssignment.stock_load.barcode_id+`" type="text" class="form-control" />
              </div>
            </td>
          </tr>
        </table>`
        return html
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
  table.xfer-table {
    padding: 20px;
    width: 100%;
    td {
      width: 50%;
      text-align: left;
      input, select {
        width: 200px;
      }
    }
  }
</style>
