<template>
  <div>
    <div class="card" v-loading="isLoading">
      <div class="card-header">
        <h5 class="card-title">Shipments</h5>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default"
            v-model="pagination.per_page"
            placeholder="Per page"
            @change="searchShipments">
            <el-option
              class="select-default"
              v-for="item in pagination.per_page_options"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <p-checkbox v-model="showPreTransit" :inline="true">Pre Transit</p-checkbox>
            <p-checkbox v-model="showInTransit" :inline="true">In Transit</p-checkbox>
            <p-checkbox v-model="showDelivered" :inline="true">Delivered</p-checkbox>
            <fg-input class="input-sm"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split"
                      @keyup.enter="searchShipments">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12">
          <el-table :data="shipmentsData.data" header-row-class-name="text-primary" row-class-name="shipment-row" ref="table" :row-key="getRowKey" @sort-change="sortChange" :default-sort="{prop: 'ship_date', order: 'descending'}">
            <el-table-column label="Ship Date" sortable="custom">
              <template slot-scope="props">
                {{ friendlyDate(props.row.ship_date) }}
              </template>
            </el-table-column>
            <el-table-column label="Origin">
              <template slot-scope="props">
                {{ props.row.order.origin.name }}
              </template>
            </el-table-column>
            <el-table-column prop="order.order_num" sortable="custom"
                             label="Order #">
            </el-table-column>
            <el-table-column label="Name" width="175" sortable="custom">
              <template slot-scope="props">
                <span v-html="nameDisplay(props.row)"></span>
              </template>
            </el-table-column>
            <el-table-column prop="carrier.name" sortable="custom"
                             label="Carrier">
            </el-table-column>
            <el-table-column label="Tracking #" min-width="120">
              <template slot-scope="props">
                <div v-if="trackingUrlKnown(props.row)">
                  <a :href="trackingUrl(props.row)" target="_blank">{{ props.row.tracking_num }}</a>
                </div>
                <div v-if="!trackingUrlKnown(props.row)">{{ props.row.tracking_num }}</a></div>
              </template>
            </el-table-column>
            <el-table-column label="Status" sortable="custom">
              <template slot-scope="props">
                {{ statusDisplay(props.row) }}
              </template>
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label="Actions">
              <template slot-scope="props">
                <p-button type="warning" size="sm" icon v-if="props.row.late_delivered" @click="lateDeliveredAction(props.row)">
                  <i class="fa fa-triangle-exclamation"></i>
                </p-button>
                <p-button type="info" size="sm" icon @click="toogleExpand(props.row)">
                  <i class="fa fa-angles-down"></i>
                </p-button>
              </template>
            </el-table-column>
            <el-table-column type="expand" width="1">
             <template slot-scope="props">
              <el-table :data="props.row.shipment_items" :border="true" :row-key="getRowKey">
                <el-table-column label="Qty" prop="qty" width="100">
                </el-table-column>
                <el-table-column label="SKU" prop="product.client_part_num" width="200">
                </el-table-column>
                <el-table-column label="Name" prop="product.name">
                </el-table-column>
              </el-table>
              <el-table :data="props.row.tracking_details" :border="true" :row-key="getRowKey">
                <el-table-column label="Event Time">
                  <template slot-scope="props">
                    {{ friendlyTimestamp(props.row.event_time) }}
                  </template>
                </el-table-column>
                <el-table-column label="Status" prop="status">
                </el-table-column>
                <el-table-column label="Description" prop="description">
                </el-table-column>
              </el-table>
             </template>
           </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{shipmentsData.from}} to {{shipmentsData.to}} of {{shipmentsData.total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.current_page"
                        :per-page="pagination.per_page"
                        :total="pagination.total"
                        @input="changePage">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>



  
</template>
<script>
  import Vue from 'vue'
  import moment from 'moment'
  import Swal from 'sweetalert2';
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import ShipmentService from 'src/services/ClientAccount/shipment.service'
  import DOMPurify from 'dompurify'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default{
    components: {
      PPagination
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data () {
      return {
        isLoading: true,
        shipmentsData: {
          current_page: 1,
          per_page: 10
        },
        pagination: {
          per_page: 10,
          current_page: 1,
          sort_by: '',
          sort_order: '',
          per_page_options: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        showPreTransit: true,
        showInTransit: true,
        showDelivered: false
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        this.searchShipments();
      },
      async searchShipments()
      {
        this.isLoading = true
        let params = {
          ignore_delivered: !this.showDelivered,
          ignore_in_transit: !this.showInTransit,
          ignore_pre_transit: !this.showPreTransit
        }
        let response = await ShipmentService.index(this.pagination.current_page, this.pagination.per_page, this.searchQuery, this.pagination.sort_by, this.pagination.sort_order, params)
        this.shipmentsData = response.data
        this.pagination.current_page = this.shipmentsData.current_page
        this.pagination.per_page = this.shipmentsData.per_page
        this.pagination.total = this.shipmentsData.total
        this.isLoading = false
      },
      getRowKey (row) {
        return row.id
      },
      async toogleExpand(row) {
        let $table = this.$refs.table
        // if (row.stock_assignments === undefined) {
        //   this.isLoading = true
        //   let response = await ProductService.getStock(row.id)
        //   row.stock_assignments = response.data.stock_assignments
        //   this.isLoading = false
        // }
        $table.toggleRowExpansion(row)
      },
      changePage (page) {
        if (page != this.shipmentsData.current_page) {
          // console.log('changing page')
          this.shipmentsData.current_page = page
          this.searchShipments()
        }
      },
      friendlyDate (dateString) {
        return moment(dateString).format('MM/DD/YYYY')
      },
      friendlyTimestamp (timestamp) {
        return moment(timestamp).format('LLL')
      },
      friendlyDateTime (timestamp) {
        if (timestamp === undefined || timestamp === '') return 'Never'
        else return moment(timestamp.seconds * 1000).format('LLL')
      },
      trackingUrlKnown (shipment) {
        let carrierName = shipment.carrier.name
        if (carrierName == 'USPS' || carrierName == 'UPS') return true
        return false
      },
      trackingUrl (shipment) {
        let carrierName = shipment.carrier.name
        let trackingNum = shipment.tracking_num
        if (carrierName == 'USPS') return 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=' + trackingNum
        else if (carrierName == 'UPS') return 'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + trackingNum + '&requester=ST/trackdetails'
        else return 'https://www.google.com/search?q=' + trackingNum
      },
      statusDisplay (shipment) {
        if (shipment.status == null) return 'unknown'
        else return shipment.status
      },
      nameDisplay (shipment) {
        // add single br after first name (only replaces first space)
        let name = shipment.ship_to_name.replace(' ', '<br>')
        // to be extra safe since we're using vulnerable v-html (possible injection), we
        // are making sure any html other than <br> is stripped
        const sanitazedName = DOMPurify.sanitize(name, {ALLOWED_TAGS: ['br'], ALLOWED_ATTR: []});
        return sanitazedName;
      },
      etaDisplay (shipment) {
        if (shipment.eta != null) return this.friendlyDate(shipment.eta)
        else if (shipment.original_eta != null) return this.friendlyDate(shipment.orginal_eta)
        else return 'unknown'
      },
      lateDeliveredAction (shipment) {
        const notice = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        });
        notice.fire({
          title: 'Possible Late Delivery',
          text: `Note that this system's longest estimates are based on domestic shipping times so most international shipments will end up flagged as possibly late even when they are not.`,
        });
      },
      sortChange (sortProps) {
          this.pagination.sort_by = this.colNameFromLabel(sortProps.column.label)
          this.pagination.sort_order = sortProps.column.order == 'ascending' ? 'asc' : 'desc'
          this.searchShipments()
      },
      colNameFromLabel (label) {
        if (label == 'Order #') return 'order_num'
        if (label == 'Name') return 'ship_to_name'
        if (label == 'Carrier') return 'carrier_name'
        if (label == 'Status') return 'status'
        else return 'ship_date'
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    },
    watch: {
      showPreTransit(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.searchShipments()
        }
      },
      showInTransit(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.searchShipments()
        }
      },
      showDelivered(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.searchShipments()
        }
      }
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
  }
  .el-table {
    .shipment-row {
      font-weight: bold;
    }
  }
</style>
