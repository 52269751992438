<template>
  <div>
    <div class="card" v-loading="isLoading">
      <div class="card-header">
        <h5 class="card-title">Orders</h5>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select
            class="select-default"
            v-model="pagination.per_page"
            placeholder="Per page"
            @change="searchOrders">
            <el-option
              class="select-default"
              v-for="item in pagination.per_page_options"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split"
                      @keyup.enter="searchOrders">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12">
          <el-table :data="ordersData.data" header-row-class-name="text-primary" ref="table" :row-key="getRowKey">
            <el-table-column prop="order_date"
                             label="Order Date">
            </el-table-column>
            <el-table-column prop="ship_to_name"
                             label="Name">
            </el-table-column>
            <el-table-column prop="ship_to_street_1"
                             label="Address">
            </el-table-column>
            <el-table-column prop="ship_to_city"
                             label="City">
            </el-table-column>
            <el-table-column prop="ship_to_state"
                             label="State">
            </el-table-column>
            <el-table-column prop="ship_to_zip"
                             label="Zip">
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label="Actions">
              <template slot-scope="props">
                <p-button type="warning" size="sm" icon @click="toogleExpand(props.row)">
                  <i class="fa fa-angles-down"></i>
                </p-button>
              </template>
            </el-table-column>
            <el-table-column type="expand" width="1">
             <template slot-scope="props">
              <el-table :data="props.row.order_items" :border="true" :row-key="getRowKey">
                <el-table-column label="Qty" prop="qty" width="100">
                </el-table-column>
                <el-table-column label="SKU" prop="product.client_part_num" width="200">
                </el-table-column>
                <el-table-column label="Name" prop="product.name">
                </el-table-column>
              </el-table>
             </template>
           </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{ordersData.from}} to {{ordersData.to}} of {{ordersData.total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.current_page"
                        :per-page="pagination.per_page"
                        :total="pagination.total"
                        @input="changePage">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>



  
</template>
<script>
  import Vue from 'vue'
  import moment from 'moment'
  import Swal from 'sweetalert2';
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import OrderService from 'src/services/ClientAccount/order.service'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default{
    components: {
      PPagination
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data () {
      return {
        isLoading: true,
        ordersData: {
          current_page: 1,
          per_page: 10
        },
        pagination: {
          per_page: 10,
          current_page: 1,
          per_page_options: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: ''
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        this.searchOrders();
      },
      async searchOrders()
      {
        this.isLoading = true
        let response = await OrderService.index(this.pagination.current_page, this.pagination.per_page, this.searchQuery)
        this.ordersData = response.data
        this.pagination.current_page = this.ordersData.current_page
        this.pagination.per_page = this.ordersData.per_page
        this.pagination.total = this.ordersData.total
        console.log('ordersData', this.ordersData)
        this.isLoading = false
      },
      getRowKey (row) {
        return row.id
      },
      async toogleExpand(row) {
        let $table = this.$refs.table
        // if (row.stock_assignments === undefined) {
        //   this.isLoading = true
        //   let response = await ProductService.getStock(row.id)
        //   row.stock_assignments = response.data.stock_assignments
        //   this.isLoading = false
        // }
        $table.toggleRowExpansion(row)
      },
      changePage (page) {
        console.log('page', page, 'orderData.current_page', this.ordersData.current_page)
        if (page != this.ordersData.current_page) {
          // console.log('changing page')
          this.ordersData.current_page = page
          this.searchOrders()
        }
        // this.searchOrders()
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
  }
</style>
