<template>
  <div class="row" v-loading="isLoading">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title">
          Add Inbound Shipment
        </h4>
        <p-button type="info" size="sm" @click.prevent="goInboundShipments()">Back</p-button>

        <fg-input label="Ship To" style="max-width: 400px">
          <el-select class="select-info"
                      size="large"
                      placeholder="Warehouse"
                      v-model="inboundShipment.storage_facility_id">
            <el-option v-for="option in storageFacilities"
                        class="select-info"
                        :value="option.id"
                        :label="storageFacilityLabel(option)"
                        :key="option.id">
            </el-option>
          </el-select>
        </fg-input>
        <fg-input v-model="inboundShipment.tracking_num" label="Tracking Number"></fg-input>
        <fg-input v-model="inboundShipment.carrier" label="Carrier"></fg-input>
        <div class="form-group has-label">
          <label>ETA Date</label>
          <el-date-picker v-model="inboundShipment.eta_start_time" type="date" placeholder="Pick a day"
                          :picker-options="datePickerOptions">
          </el-date-picker>
        </div>
        <div class="form-group has-label">
          <label>Notes</label>
          <textarea v-model="inboundShipment.notes" class="form-control" rows="3"></textarea>
        </div>
        <fg-input label="Contents" style="max-width: 400px">
          <el-select class="select-info"
                      size="large"
                      placeholder="Contents"
                      v-model="inboundShipment.contents">
            <el-option v-for="option in contents"
                        class="select-info"
                        :value="option.name"
                        :label="option.name"
                        :key="option.name">
            </el-option>
          </el-select>
        </fg-input>
        <div class="form-group has-label">
          <label>Added Files or Images</label>
          <div style="width: 400px;">
            <multi-file-uploader @tempFileIdUpdated="addTempFileId" />
          </div>
        </div>
        <div v-if="isInventory && productsAdded" class="form-group has-label">
          <label>Added Products</label>
          <div class="row" v-for="product in inboundShipment.products">
            <div class="col-md-8">
              <fg-input :value="productLabel(product)" disabled></fg-input>
            </div>
            <div class="col-md-2">
              <fg-input :value="product.sent_qty" disabled></fg-input>
            </div>
            <div class="col-md-2">
              <p-button type="info" size="sm" @click.prevent="removeProduct(product.id)">Remove</p-button>
            </div>
          </div>
        </div>
        <div v-if="isInventory" class="form-group has-label">
          <label>Select &amp; Add Products</label>
          <div class="row">
            <div class="col-md-8">
              <fg-input>
                <el-select class="select-info"
                            size="large"
                            placeholder="Product"
                            v-model="selProdId">
                  <el-option v-for="option in products"
                              class="select-info"
                              :value="option.id"
                              :label="productLabel(option)"
                              :key="option.id">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <fg-input v-model="selQty" placeholder="Quantity"></fg-input>
            </div>
            <div class="col-md-2">
              <p-button type="info" size="sm" @click.prevent="addProduct()">Add</p-button>
            </div>
          </div>
        </div>

        <p-button type="info" @click.prevent="addInboundShipment()">Submit</p-button>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'
  import { DatePicker, Select, Option } from 'element-ui'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import StorageFacilityService from 'src/services/ClientAccount/storageFacility.service'
  import ProductService from 'src/services/ClientAccount/product.service'
  import InboundShipmentService from 'src/services/ClientAccount/inboundShipment.service'
  import MultiFileUploader from 'src/components/SharedComponents/MultiFileUploader.vue'

  export default {
    components: {
      Card,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      MultiFileUploader
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      isInventory () {
        return this.inboundShipment.contents === 'inventory'
      },
      productsAdded () {
        return this.inboundShipment.products.length > 0
      }
    },
    data() {
      return {
        isLoading: false,
        storageFacilities: [],
        products: [],
        selProdId: '',
        selQty: '',
        contents: [
          {name: 'inventory'},
          {name: 'other'}
        ],
        inboundShipment: {
          storage_facility_id: '',
          tracking_num: '',
          carrier: '',
          eta_start_time: '',
          notes: '',
          contents: 'inventory',
          products: [],
          temp_file_ids: []
        },
        datePickerOptions: {
          shortcuts: [{
            text: 'Today',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: 'Tomorrow',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '1 Week',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          },
          {
            text: '2 Weeks',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 14)
              picker.$emit('pick', date)
            }
          }]
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        this.isLoading = true
        Promise.all([this.loadStorageFacilities(), this.loadProducts()]).then((values) => {
          this.isLoading = false
        })
      },
      async loadStorageFacilities () {
        let response = await StorageFacilityService.index()
        this.storageFacilities = response.data
        if (this.storageFacilities.length === 1) this.inboundShipment.storage_facility_id = this.storageFacilities[0].id
      },
      async loadProducts () {
        let response = await ProductService.index()
        this.products = response.data
      },
      addTempFileId (newId) {
        this.inboundShipment.temp_file_ids.push(newId)
      },
      addProduct () {
        let qty = parseInt(this.selQty)
        if (this.inboundShipment.products.find( ({ id }) => id === this.selProdId )) {
          this.showNotify('Product already added', 'danger', 'Failed')
          return
        } else if (isNaN(qty)) {
          this.showNotify('Invalid quantity provided', 'danger', 'Failed')
          return
        }
        let prod = this.products.find( ({ id }) => id === this.selProdId )
        prod.sent_qty = qty
        this.inboundShipment.products.push(prod)
        this.selProdId = ''
        this.selQty = ''
      },
      removeProduct (id) {
        this.inboundShipment.products.splice(this.inboundShipment.products.findIndex( ({ id }) => id === id ), 1);
      },
      goInboundShipments () {
        this.$router.replace({ name: 'Client Inbound Shipments' })
      },
      async addInboundShipment () {
        try {
          this.loading = true
          await InboundShipmentService.create(this.inboundShipment)
          this.showNotify('Inbound Shipment created', 'success', 'Success')
          this.loading = false
          this.goInboundShipments()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.loading = false
        }
      },
      storageFacilityLabel (storageFacility) {
        if (storageFacility.address) return storageFacility.name + ' - ' + storageFacility.address
        else return storageFacility.name
      },
      productLabel (product) {
        if(product.client_part_num) return product.name + ' (' + product.client_part_num + ')'
        else return product.name
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
</style>
