import axios from 'axios'

class InboundShipmentService {
  index () {
    return axios.get('/api/provider-account/inbound-shipments')
  }

  get (id) {
    return axios.get('/api/provider-account/inbound-shipments/' + id)
  }

  create (inboundShipment) {
    const data = new FormData()
    data.append('storage_facility_id', inboundShipment.storage_facility_id)
    data.append('contents', inboundShipment.contents)
    data.append('tracking_num', inboundShipment.tracking_num)
    data.append('notes', inboundShipment.notes)
    data.append('products', JSON.stringify(inboundShipment.products))
    data.append('temp_file_ids', JSON.stringify(inboundShipment.temp_file_ids))
    // data.append('img_temp_file_id', brand.imgTempFileId)
    return axios.post('/api/provider-account/inbound-shipments', data)
  }
}

export default new InboundShipmentService()
