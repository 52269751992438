<template>
  <div>
    <div class="card" v-loading="isLoading">
      <div class="card-header">
        <h5 class="card-title">Inbound Shipments</h5>
        <p-button type="info" size="sm" @click.prevent="goAddInboundShipment()">New Inbound Shipment</p-button>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table :data="inboundShipments" header-row-class-name="text-primary" :row-class-name="tableRowClassName">
            <el-table-column label="To Warehouse">
              <template slot-scope="props">
                {{ storageFacilityLabel(props.row) }}
              </template>
            </el-table-column>
            <el-table-column label="Created">
              <template slot-scope="props">
                {{ friendlyDate(props.row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column prop="tracking_num"
                             label="Tracking Num">
            </el-table-column>
            <el-table-column label="Contents">
              <template slot-scope="props">
                {{ contentsDesc(props.row) }}
              </template>
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label="Actions">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import moment from 'moment'
  import Swal from 'sweetalert2';
  import {Table, TableColumn} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import InboundShipmentService from 'src/services/ClientAccount/inboundShipment.service'
  Vue.use(Table)
  Vue.use(TableColumn)
  export default{
    components: {
      PSwitch
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data () {
      return {
        isLoading: true,
        inboundShipments: []
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        this.isLoading = true
        this.loadInboundShipments()
      },
      async loadInboundShipments () {
        let response = await InboundShipmentService.index()
        this.inboundShipments = response.data
        this.isLoading = false
      },
      goAddInboundShipment () {
        this.$router.replace({ name: 'Client Add Inbound Shipment' })
      },
      handleEdit (index, row) {
        this.$router.push({ name: 'Client Edit Inbound Shipment', params: { id: row.id } })
      },
      handleDelete (index, row) {
        alert(`not yet functional`)
      },
      storageFacilityLabel (inboundShipment) {
        if (inboundShipment.storage_facility.address) return inboundShipment.storage_facility.name + ' - ' + inboundShipment.storage_facility.address
        else return inboundShipment.storage_facility.name
      },
      contentsDesc (inboundShipment) {
        if (inboundShipment.contents === 'inventory') {
          var str = ''
          for (var i = 0; i < inboundShipment.inbound_shipment_products.length; i++) {
            let is_prod = inboundShipment.inbound_shipment_products[i]
            if (str !== '') str = ' | '
            str += is_prod.sent_qty + ' ' + is_prod.product.name
            if (is_prod.product.client_part_num) str += ' (' + is_prod.product.client_part_num + ')'
            return str
          }
        } else {
          return 'Notes: ' + inboundShipment.notes
        }
      },
      friendlyDate (dateString) {
        return moment(dateString).format('MM/DD/YYYY')
      },
      tableRowClassName ({row, rowIndex}) {
        if (row.status === 'received') return 'shipment-received'
        return ''
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
  .el-table tr.shipment-received {
    background-color: #efefef !important;
  }
</style>
