import axios from 'axios'

class ShipmentService {
  index (page = 1, per_page = 10, query = '', sort_by = 'ship_date', sort_order = 'desc', params = {}) {
    let param_str = '?page=' + page + '&per_page=' + per_page + '&sort_by=' + sort_by + '&sort_order=' + sort_order;
    if (query != '') param_str += '&query=' + query;
    if (params.hasOwnProperty('ignore_delivered') && params.ignore_delivered) param_str += '&ignore_delivered=true'
    if (params.hasOwnProperty('ignore_in_transit') && params.ignore_in_transit) param_str += '&ignore_in_transit=true'
    if (params.hasOwnProperty('ignore_pre_transit') && params.ignore_pre_transit) param_str += '&ignore_pre_transit=true'
    return axios.get('/api/client-account/shipments' + param_str)
  }
}

export default new ShipmentService()
