import axios from 'axios'

class StorageLocationService {
  index () {
    return axios.get('/api/provider-account/storage-locations')
  }

  get (id) {
    return axios.get('/api/provider-account/storage-locations/' + id)
    // return axios({
    //   method: 'get',
    //   url: '/api/provider-account/warehouse',
    //   data: { id: id }
    // })
  }

  create (storageLocation) {
    const data = new FormData()
    data.append('storage_location_id', storageLocation.storage_location_id)
    data.append('barcode_id', storageLocation.barcode_id)
    data.append('name', storageLocation.name)
    // data.append('img_temp_file_id', brand.imgTempFileId)
    return axios.post('/api/provider-account/storage-locations', data)
  }

  update (id, storageLocation) {
    const data = new FormData()
    data.append('_method', 'PUT')
    data.append('id', id)
    data.append('barcode_id', storageLocation.barcode_id)
    data.append('name', storageLocation.name)
    // data.append('imgFile', productTypeData.imgFile)
    // return Api.post('/api/provider-account/warehouses/' + id + '?_method=PUT', data)
    return axios({
      method: 'post',
      url: '/api/provider-account/storage-locations/'+id,
      data: data
    })
  }

  destroy (id) {
    return axios({
      method: 'delete',
      url: '/api/provider-account/storage-locations',
      data: { id: id }
    })
  }
}

export default new StorageLocationService()
