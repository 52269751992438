<template>
  <div class="row" v-loading="isLoading">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title">
          Receive/Edit Inbound Shipment
        </h4>
        <p-button type="info" size="sm" @click.prevent="goInboundShipments()">Back</p-button>

        <div>
          <div class="row">
            <div class="col-md-6">
              <fg-input v-model="inboundShipment.client_account.business_name" label="Ship From" disabled></fg-input>
            </div>
            <div class="col-md-6">
              <fg-input v-model="inboundShipment.storage_facility.name" label="Ship To" disabled></fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <fg-input v-model="inboundShipment.tracking_num" label="Tracking Number" :disabled="isReceived"></fg-input>
            </div>
            <div class="col-md-4">
              <fg-input v-model="inboundShipment.carrier" label="Carrier" :disabled="isReceived"></fg-input>
            </div>
            <div class="col-md-4">
              <div class="form-group has-label">
                <label>ETA Date</label>
                <el-date-picker v-model="inboundShipment.eta_start_time" type="date" placeholder="Pick a day"
                                :picker-options="datePickerOptions">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group has-label">
          <label>Notes</label>
          <textarea :disabled="isReceived" v-model="inboundShipment.notes" class="form-control" rows="3"></textarea>
        </div>
        <fg-input label="Contents" style="max-width: 400px">
          <el-select :disabled="isReceived"
                      class="select-info"
                      size="large"
                      placeholder="Contents"
                      v-model="inboundShipment.contents">
            <el-option v-for="option in contents"
                        class="select-info"
                        :value="option.name"
                        :label="option.name"
                        :key="option.name">
            </el-option>
          </el-select>
        </fg-input>
        <div class="form-group has-label">
          <label>Attached Files/Images</label>
          <ul>
            <li v-for="file in inboundShipment.model_files">
              <a :href="file.url" target="_blank">{{ file.filename }}</a> {{ fileLabel(file) }}
            </li>
          </ul>
          <div v-if="!isReceived" style="width: 400px;">
            <multi-file-uploader @tempFileIdUpdated="addTempFileId" />
          </div>
        </div>
        <div v-if="isInventory && productsAdded" class="form-group has-label">
          <label><strong>Sent / Received</strong></label>
          <div class="row" v-for="product in inboundShipment.products">
            <div class="col-md-8">
              <fg-input :value="productLabel(product)" label="Product" disabled></fg-input>
            </div>
            <div class="col-md-2">
              <fg-input :value="product.sent_qty" label="Sent Qty" disabled></fg-input>
            </div>
            <div class="col-md-2">
              <fg-input :value="product.received_qty" label="Received Qty" disabled></fg-input>
            </div>
          </div>
        </div>
        <div v-if="receivedProductsAdded" class="form-group has-label">
          <label><strong>Receiving Now</strong></label>
          <div class="row" v-for="(product, index) in inboundShipment.received_products">
            <div class="col-md-6">
              <fg-input :value="productLabel(product)" label="Product" disabled></fg-input>
            </div>
            <div class="col-md-2">
              <fg-input :value="product.received_qty" label="Received Qty" disabled></fg-input>
            </div>
            <div class="col-md-2">
              <fg-input :value="product.load_num" label="Load Num" disabled></fg-input>
            </div>
            <div class="col-md-2">
              <div><label>Remove</label></div>
              <p-button type="info" size="sm" @click.prevent="removeReceivedProduct(index)">Remove</p-button>
            </div>
          </div>
        </div>
        <div v-if="isInventory && !isReceived" class="form-group has-label">
          <label>Select &amp; Receive Products</label>
          <div class="row">
            <div class="col-md-6">
              <fg-input>
                <el-select class="select-info"
                            size="large"
                            placeholder="Product"
                            v-model="selProdId">
                  <el-option v-for="option in products"
                              class="select-info"
                              :value="option.id"
                              :label="productLabel(option)"
                              :key="option.id">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-2">
              <fg-input v-model="selQty" placeholder="Receive Qty"></fg-input>
            </div>
            <div class="col-md-2">
              <fg-input v-model="selLoadNum" placeholder="Load Num"></fg-input>
            </div>
            <div class="col-md-2">
              <p-button type="info" size="sm" @click.prevent="addReceivedProduct()">Add</p-button>
            </div>
          </div>
        </div>

        <p-button :disabled="isReceived" type="info" @click.prevent="submit()">{{ btnSubmitLabel }}</p-button>
        <p-checkbox v-if="!isReceived" :checked="false" v-model="inboundShipment.close">Close Shipment</p-checkbox>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'
  import { DatePicker, Select, Option } from 'element-ui'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import Swal from 'sweetalert2';
  import ProductService from 'src/services/ProviderAccount/product.service'
  import InboundShipmentService from 'src/services/ProviderAccount/inboundShipment.service'
  import ReceiveInboundShipmentService from 'src/services/ProviderAccount/receiveInboundShipment.service'
  import MultiFileUploader from 'src/components/SharedComponents/MultiFileUploader.vue'

  export default {
    components: {
      Card,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      MultiFileUploader
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      isInventory () {
        return this.inboundShipment.contents === 'inventory'
      },
      productsAdded () {
        return this.inboundShipment.products.length > 0
      },
      receivedProductsAdded () {
        return this.inboundShipment.received_products.length > 0
      },
      isReceived () {
        return this.inboundShipment.status === 'received'
      },
      btnSubmitLabel () {
        if (this.isReceived) return 'Closed';
        else return 'Submit';
      }
    },
    data() {
      return {
        isLoading: false,
        products: [],
        selProdId: '',
        selQty: '',
        selLoadNum: '',
        contents: [
          {name: 'inventory'},
          {name: 'other'}
        ],
        inboundShipment: {
          client_account: {
            business_name: ''
          },
          storage_facility: {
            name: ''
          },
          storage_facility_id: '',
          tracking_num: '',
          carrier: '',
          eta_start_time: '',
          notes: '',
          contents: 'inventory',
          products: [],
          inbound_shipment_products: [],
          model_files: [],
          temp_file_ids: [],
          received_products: [],
          status: 'unknown',
          close: false
        },
        datePickerOptions: {
          shortcuts: [{
            text: 'Today',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: 'Tomorrow',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '1 Week',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          },
          {
            text: '2 Weeks',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 14)
              picker.$emit('pick', date)
            }
          }]
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        if (this.$route.params.id) {
          this.loadInboundShipment(this.$route.params.id)
          // this.isLoading = true
          // Promise.all([this.loadInboundShipment(this.$route.params.id)]).then((values) => {
          //   this.isLoading = false
          // })
        } else {
          let msg = "id param does not exist so no inbound shipment to load"
          this.showNotify(msg, 'danger', 'Failed')
          this.goInboundShipments()
        }
      },
      async loadInboundShipment (id) {
        try {
          this.isLoading = true

          let response = await InboundShipmentService.get(id)
          let iship = response.data
          this.inboundShipment.inbound_shipment_id = iship.id
          this.inboundShipment.client_account = iship.client_account
          this.inboundShipment.storage_facility = iship.storage_facility
          this.inboundShipment.tracking_num = iship.tracking_num
          this.inboundShipment.carrier = iship.carrier
          this.inboundShipment.eta_start_time = iship.eta_start_time
          this.inboundShipment.notes = iship.notes
          this.inboundShipment.contents = iship.contents
          this.inboundShipment.inbound_shipment_products = iship.inbound_shipment_products
          this.inboundShipment.model_files = iship.model_files
          this.inboundShipment.status = iship.status

          console.log(response.data)
          console.log(this.inboundShipment.client_account.business_name)

          response = await ProductService.index(this.inboundShipment.account_id)
          this.products = response.data

          console.log('products', this.products)
          this.addLoadedProducts()

          this.isLoading = false
        } catch (e) {
          if (e.response) {
            let msg = this.errorMsgFromResponse(e.response)
            this.showNotify(msg, 'danger', 'Failed')
          } else {
            this.showNotify(e, 'danger', 'Failed')
          }
          this.goInboundShipments()
        }
      },
      async loadProducts () {
        let response = await ProductService.index()
        this.products = response.data
      },
      addTempFileId (newId) {
        this.inboundShipment.temp_file_ids.push(newId)
      },
      addLoadedProducts () {
        for(var i = 0; i < this.inboundShipment.inbound_shipment_products.length; i++) {
          let isp = this.inboundShipment.inbound_shipment_products[i]
          this.addProduct(isp.product_id, isp.sent_qty, isp.received_qty)
        }
      },
      addProduct (prodId, sentQty, receivedQty) {
        if (this.inboundShipment.products.find( ({ id }) => id === prodId )) {
          this.showNotify('Product already added', 'danger', 'Failed')
          return
        } else if (isNaN(sentQty)) {
          this.showNotify('Invalid quantity provided', 'danger', 'Failed')
          return
        }
        let prod = this.products.find( ({ id }) => id === prodId )
        prod.sent_qty = sentQty
        if (!isNaN(receivedQty)) prod.received_qty = receivedQty
        else prod.received_qty = 0
        this.inboundShipment.products.push(prod)
        this.selProdId = ''
        this.selQty = ''
      },
      addReceivedProduct () {
        let qty = parseInt(this.selQty)
        let loadNum = this.selLoadNum
        let selProd = this.products.find( ({ id }) => id === this.selProdId )
        if (!selProd) {
          this.showNotify('No product selected', 'danger', 'Failed')
          return
        }
        if (isNaN(qty)) {
          this.showNotify('Invalid quantity provided', 'danger', 'Failed')
          return
        }
        if (!loadNum) {
          this.showNotify('Invalid load num provided', 'danger', 'Failed')
          return
        }
        let prod = JSON.parse(JSON.stringify(selProd));
        prod.received_qty = qty
        prod.load_num = loadNum
        this.inboundShipment.received_products.push(prod)
        this.selProdId = ''
        this.selQty = ''
        this.selLoadNum = ''

        let sentProd = this.inboundShipment.products.find( ({ id }) => id === prod.id )
        if (!sentProd) {
          sentProd = JSON.parse(JSON.stringify(prod))
          sentProd.sent_qty = 0
          this.inboundShipment.products.push(sentProd)
        }
        sentProd.received_qty += qty
      },
      removeProduct (id) {
        this.inboundShipment.products.splice(this.inboundShipment.products.findIndex( ({ id }) => id === id ), 1);
      },
      removeReceivedProduct (index) {
        this.inboundShipment.products.splice(index, 1);
      },
      goInboundShipments () {
        this.$router.replace({ name: 'Warehouse Inbound Shipments' })
      },
      submit () {
        if (!this.inboundShipment.close) {
          return this.receiveInboundShipment()
        }
        const swalConfirm = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        swalConfirm
          .fire({
            title: `Are you sure you want to close this shipment?`,
            text: `Closing a shipment is permanent. You will not be able to receive anymore products for this shipment.`,
            confirmButtonText: "Yes, close it!",
            cancelButtonText: "Cancel",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              this.receiveInboundShipment()
            }
          });
      },
      async receiveInboundShipment  () {
        try {
          this.loading = true
          await ReceiveInboundShipmentService.create(this.inboundShipment)
          this.showNotify('Inbound Shipment received', 'success', 'Success')
          this.loading = false
          this.goInboundShipments()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.loading = false
        }
      },
      productLabel (product) {
        if(product.client_part_num) return product.name + ' (' + product.client_part_num + ')'
        else return product.name
      },
      fileLabel (file) {
        if (file.label) return '(' + file.label + ')'
        else return ''
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
</style>
