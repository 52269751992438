<template>
  <div class="row" v-loading="isLoading">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title">
          Edit Storage Location
        </h4>
        <p-button type="info" size="sm" @click.prevent="goStorageLocations()">Back</p-button>

        <fg-input v-model="storageLocation.storage_facility.name" label="Warehouse" disabled></fg-input>
        <fg-input v-model="storageLocation.barcode_id" label="Barcode ID"></fg-input>
        <fg-input v-model="storageLocation.name" label="Name"></fg-input>

        <p-button type="info" @click.prevent="editStorageLocation()">Submit</p-button>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import StorageLocationService from 'src/services/ProviderAccount/storageLocation.service'

  export default {
    components: {
      Card
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data() {
      return {
        isLoading: true,
        storageLocation: {
          id: '',
          barcode_id: '',
          name: '',
          storage_facility: {
            name: ''
          }
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        if (this.$route.params.id) {
          this.isLoading = true
          Promise.all([this.loadStorageLocation(this.$route.params.id)]).then((values) => {
            this.isLoading = false
          })
        } else {
          let msg = "id param does not exist so no storage location to load"
          this.showNotify(msg, 'danger', 'Failed')
          this.goStorageLocations()
        }
      },
      async loadStorageLocation (id) {
        try {
          let response = await StorageLocationService.get(id)
          this.storageLocation = response.data
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.goProducts()
        }
      },
      goStorageLocations () {
        this.$router.replace({ name: 'Storage Locations' })
      },
      async editStorageLocation () {
        try {
          this.isLoading = true
          await StorageLocationService.update(this.storageLocation.id, this.storageLocation)
          this.showNotify('Storage Location updated', 'success', 'Success')
          this.isLoading = false
          this.goStorageLocations()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.isLoading = false
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
</style>
