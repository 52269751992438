<template>
  <div class="row" v-loading="isLoading">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title">
          Add Storage Location
        </h4>
        <p-button type="info" size="sm" @click.prevent="goStorageLocations()">Back</p-button>

        <fg-input>
          <el-select class="select-info"
                      size="large"
                      placeholder="Warehouse"
                      v-model="storageLocation.warehouse_id">
            <el-option v-for="option in warehouses"
                        class="select-info"
                        :value="option.id"
                        :label="option.name"
                        :key="option.id">
            </el-option>
          </el-select>
        </fg-input>
        <fg-input v-model="storageLocation.barcode_id" label="Barcode ID"></fg-input>
        <fg-input v-model="storageLocation.name" label="Name"></fg-input>

        <p-button type="info" @click.prevent="addStorageLocation()">Submit</p-button>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'
  import { Select, Option } from 'element-ui'
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import StorageFacilityService from 'src/services/ProviderAccount/storageFacility.service'
  import StorageLocationService from 'src/services/ProviderAccount/storageLocation.service'

  export default {
    components: {
      Card,
      [Select.name]: Select,
      [Option.name]: Option
    },
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data() {
      return {
        isLoading: true,
        warehouses: [],
        storageLocation: {
          warehouse_id: '',
          barcode_id: '',
          name: ''
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        this.isLoading = true
        this.loadWarehouses()
      },
      async loadWarehouses () {
        let response = await StorageFacilityService.index()
        this.warehouses = response.data
        this.isLoading = false
      },
      goStorageLocations () {
        this.$router.replace({ name: 'Storage Locations' })
      },
      async addStorageLocation () {
        try {
          this.isLoading = true
          await StorageLocationService.create(this.storageLocation)
          this.showNotify('Storage Location created', 'success', 'Success')
          this.isLoading = false
          this.goStorageLocations()
        } catch (e) {
          let msg = this.errorMsgFromResponse(e.response)
          this.showNotify(msg, 'danger', 'Failed')
          this.isLoading = false
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
</style>
