import axios from 'axios'
import moment from 'moment'

class InboundShipmentService {
  index () {
    return axios.get('/api/client-account/inbound-shipments')
  }

  get (id) {
    return axios.get('/api/client-account/inbound-shipments/' + id)
  }

  create (inboundShipment) {
    const data = new FormData()
    data.append('storage_facility_id', inboundShipment.storage_facility_id)
    data.append('contents', inboundShipment.contents)
    data.append('tracking_num', inboundShipment.tracking_num)
    data.append('carrier', inboundShipment.carrier)
    data.append('eta_start_time', moment(inboundShipment.eta_start_time).format())
    data.append('notes', inboundShipment.notes)
    data.append('products', JSON.stringify(inboundShipment.products))
    data.append('temp_file_ids', JSON.stringify(inboundShipment.temp_file_ids))
    // data.append('img_temp_file_id', brand.imgTempFileId)
    return axios.post('/api/client-account/inbound-shipments', data)
  }

  update (inboundShipment) {
    const data = new FormData()
    data.append('_method', 'PUT')
    data.append('id', inboundShipment.inbound_shipment_id)
    data.append('tracking_num', inboundShipment.tracking_num)
    data.append('carrier', inboundShipment.carrier)
    data.append('eta_start_time', moment(inboundShipment.eta_start_time).format())
    data.append('notes', inboundShipment.notes)
    data.append('temp_file_ids', JSON.stringify(inboundShipment.temp_file_ids))
    return axios.post('/api/client-account/inbound-shipments', data)
  }
}

export default new InboundShipmentService()
