import axios from 'axios'

class ProductService {
  index (clientAccountId) {
    return axios.get('/api/provider-account/products', { params: { client_account_id: clientAccountId } } )
  }

  get (id) {
    return axios.get('/api/provider-account/products/' + id)
    // return axios({
    //   method: 'get',
    //   url: '/api/provider-account/warehouse',
    //   data: { id: id }
    // })
  }

  create (product) {
    const data = new FormData()
    data.append('client_account_id', product.client_account_id)
    data.append('name', product.name)
    data.append('client_part_num', product.client_part_num)
    data.append('warehouse_part_num', product.warehouse_part_num)
    data.append('upc', product.upc)
    // data.append('img_temp_file_id', brand.imgTempFileId)
    return axios.post('/api/provider-account/products', data)
  }

  update (id, product) {
    const data = new FormData()
    data.append('_method', 'PUT')
    data.append('id', id)
    data.append('name', product.name)
    data.append('client_part_num', product.client_part_num)
    data.append('warehouse_part_num', product.warehouse_part_num)
    data.append('upc', product.upc)
    // data.append('imgFile', productTypeData.imgFile)
    // return Api.post('/api/provider-account/warehouses/' + id + '?_method=PUT', data)
    return axios({
      method: 'post',
      url: '/api/provider-account/products/'+id,
      data: data
    })
  }

  destroy (id) {
    return axios({
      method: 'delete',
      url: '/api/provider-account/products',
      data: { id: id }
    })
  }

  getStock (id) {
    return axios.get('/api/provider-account/product-stock/' + id)
    // return axios({
    //   method: 'get',
    //   url: '/api/provider-account/warehouse',
    //   data: { id: id }
    // })
  }

  getStockConditions () {
    return axios.get('/api/provider-account/stock-conditions')
  }

  getStockStatuses () {
    return axios.get('/api/provider-account/stock-statuses')
  }

  transferStock (stockAssignment) {
    const data = new FormData()
    data.append('_method', 'PUT')
    data.append('qty', stockAssignment.qty)
    data.append('stock_condition_id', stockAssignment.stock_condition_id)
    data.append('stock_status_id', stockAssignment.stock_status_id)
    data.append('stock_load_barcode_id', stockAssignment.stock_load_barcode_id)
    return axios({
      method: 'post',
      url: '/api/provider-account/stock-assignments/'+stockAssignment.id,
      data: data
    })
  }
}

export default new ProductService()
